import 'regenerator-runtime/runtime';

import type { ApolloError, InMemoryCache } from '@apollo/client';
import { ConditionalWrapper, ThemeProvider } from '@elseu/sdu-titan';
import { useNextLinkResolver, useNextLinkWrapper } from '@elseu/sdu-titan/integrations/router/next';
import { ContentExportProvider } from '@elseu/sdu-titan-content-export-react';
import type { DockData } from '@elseu/sdu-titan-content-rendering';
import { GenIALProvider } from '@elseu/sdu-titan-genial';
import {
  AlertsProvider,
  AnalyticsProvider,
  AuthComponents,
  AuthenticationProvider,
  BaseLayout,
  BaseLayoutSimple,
  constructSearchUrlResolver,
  ContentRenderingProviderWithPlugins,
  ErrorBoundary,
  ErrorBoundaryPageFallback,
  GlobalSiteStyling,
  InPageSearchProviderWithRouter,
  NavigationProvider,
  OfficeDocsProvider,
  renderReadingListButtonFunction,
  renderSourceOverviewAlertColumn,
  sentryDialogOptions,
  SiteConfigProvider,
  TitanCmsProvider,
  TranslationsProvider,
  useNavigation,
  useSiteConfig,
  withBot,
  withCookies,
  withLingui,
  withServerContext,
} from '@elseu/sdu-titan-product-site-components';
import { logos } from '@elseu/sdu-titan-product-site-components/src/components/Logos';
import { ReadingListsProvider } from '@elseu/sdu-titan-reading-lists-react';
import {
  SearchAutocompleteProvider,
  SearchRootProvider,
  XposiProvider,
} from '@elseu/sdu-titan-search';
import { type Messages } from '@lingui/core';
import { useLingui } from '@lingui/react';
import * as Sentry from '@sentry/nextjs';
import type { ScopeContext } from '@sentry/types';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { useCallback, useMemo } from 'react';

import { ClientOidcProvider } from '@/auth/client';
import { useTaxviceSharePlugin } from '@/components/TaxviceSharePlugin/hooks/useTaxviceSharePlugin';
import { config } from '@/config';
import { moonlitDataLayerPlugin } from '@/helpers/analytics/moonlitDataLayerPlugin';
import { getDefaultSeo } from '@/helpers/seo';
import { urls } from '@/helpers/urls';
import {
  useApolloCacheControl,
  withApolloServerSideRender,
} from '@/hocs/withApolloServerSideRender';
import {
  shouldUseServerSideAuthentication,
  useAuthInitialization,
} from '@/hooks/useAuthInitialization';
import { loadMessages } from '@/translations/utils';

const useNextLinkWrapperWithVersion = () => {
  return useNextLinkWrapper({ nextVersion: 13 });
};

const MoonlitDockPlugin = dynamic(
  () =>
    import(
      /* webpackChunkName: "MoonlitDockPlugin" */ '../components/MoonlitDockPlugin/MoonlitDockPlugin'
    ),
  {
    ssr: false,
  },
);

const moonlitDock = (data: DockData) => <MoonlitDockPlugin {...data} />;

const handleGenIALError = (error: Error, options?: Partial<ScopeContext> | undefined) => {
  Sentry.withScope(function (scope) {
    if (options?.level) {
      scope.setLevel(options.level);
    }
    Sentry.captureException(error, {
      ...options,
      extra: {
        ...(options ? options.extra : {}),
        errorType: 'NextJS helpers/errorHandling',
      },
    });
  });
};

function AppProvidersWithAuth({ children }: React.PropsWithChildren) {
  const ApolloCacheControl = useApolloCacheControl();
  const { i18n } = useLingui();

  const {
    applicationKey,
    autocompleteList,
    bluetickGraphqlUrl,
    brandName,
    completemeGraphqlUrl,
    facetsPreset,
    featureFlags,
    graphqlUrl,
    logo,
    officeDocsGraphqlUrl,
    printGraphqlUrl,
    searchFeedbackUrl,
    searchGraphqlUrl,
    searchTabs,
    siteKey,
    urls,
    bluetickAiUrl,
    userPreferencesGraphqlUrl,
    userSourcesGraphqlUrl,
    xposiApiUrl,
    xposiUserContextUrl,
  } = useSiteConfig();

  const authenticationProps = useAuthInitialization();

  const { claims, getAccessToken: accessToken, userInfo } = authenticationProps;

  const userId = userInfo?.sub;
  const isAnonymousUser = !!claims?.anon;

  const registerCacheInstanceContentRendering = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('content-rendering-provider', cache);
    },
    [ApolloCacheControl],
  );

  const registerCacheInstanceTitanCms = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('titan-cms-provider', cache);
    },
    [ApolloCacheControl],
  );

  const handlePrintErrors = useCallback(
    (type: 'mutation' | 'subscription', error?: ApolloError) => {
      if (!error) return;

      Sentry.captureException(error, {
        extra: {
          errorType: `ContentExportApi${type.charAt(0).toUpperCase() + type.slice(1)}Error`,
          errorMessage: error.message,
        },
      });
    },
    [],
  );

  const taxviceSharePlugin = useTaxviceSharePlugin();

  const dockItems = useMemo(
    () => (config.featureFlags.includes('WITH_TAXVICE_SHARING') ? [taxviceSharePlugin] : []),
    [taxviceSharePlugin],
  );
  const docks = useMemo(
    () => (config.featureFlags.includes('WITH_MOONLIT') ? [moonlitDock] : []),
    [],
  );
  const analyticsPlugins = useMemo(
    () => (config.featureFlags.includes('WITH_MOONLIT') ? [moonlitDataLayerPlugin()] : []),
    [],
  );

  const {
    alertsUrl,
    contentUrl,
    editionUrl,
    magazineUrl,
    magazinesUrl,
    newsOverviewUrl,
    newsSourceUrl,
    pnNavigationUrl,
    readingListsUrl,
    searchUrl,
    bluetickUrl,
  } = urls;

  const contentRenderingUrls = {
    alertsUrl,
    contentUrl,
    editionUrl,
    magazinesUrl,
    magazineUrl,
    newsOverviewUrl,
    newsSourceUrl,
    pnNavigationUrl,
    readingListsUrl,
    searchUrl,
    bluetickUrl: config.featureFlags.includes('WITH_BLUETICK_PASSAGES') ? bluetickUrl : undefined,
  };

  const { setShown } = useNavigation();

  const searchUrlResolver = useMemo(
    () =>
      constructSearchUrlResolver({
        searchUrl: urls.searchUrl,
        magazineUrl: urls.magazineUrl,
        tabs: searchTabs,
        tabsFacet: facetsPreset.tabsFacet,
      }),
    [urls.searchUrl, urls.magazineUrl, searchTabs, facetsPreset.tabsFacet],
  );

  return (
    <ErrorBoundary
      showDialog
      dialogOptions={sentryDialogOptions}
      fallback={<ErrorBoundaryPageFallback isFullScreen brandName={brandName} logo={logo} />}
    >
      <AuthenticationProvider {...authenticationProps}>
        <AnalyticsProvider plugins={analyticsPlugins}>
          <ContentExportProvider
            accessToken={accessToken}
            applicationKey={applicationKey}
            contentGraphqlUrl={graphqlUrl}
            exportGraphqlUrl={printGraphqlUrl}
            siteName={brandName}
            urls={contentRenderingUrls}
            onError={handlePrintErrors}
          >
            <ReadingListsProvider
              hasExternalI18nProvider
              accessToken={accessToken}
              applicationKey={applicationKey}
              baseUrl={urls.readingListsUrl}
              documentUrl={urls.contentUrl}
              graphqlUrl={userPreferencesGraphqlUrl}
              hasUserSearch={
                featureFlags.WITH_READING_LIST_USER_SEARCH &&
                !!userInfo?.readingListAutocompleteEnabled
              }
              siteId={siteKey}
              userId={userId}
            >
              <SearchRootProvider
                accessToken={accessToken}
                applicationKey={applicationKey}
                bluetickGraphqlUrl={bluetickGraphqlUrl}
                bluetickUri={urls.bluetickUrl}
                completemeGraphqlUrl={completemeGraphqlUrl}
                contentBaseUri={urls.contentUrl}
                hasSimilarRulings={featureFlags.WITH_SIMILAR_RULINGS}
                isAnonymousUser={isAnonymousUser}
                locale={i18n.locale}
                renderReadingListButton={renderReadingListButtonFunction}
                renderSourceOverviewAlertColumn={
                  featureFlags.WITH_SOURCES && featureFlags.WITH_ALERTS
                    ? renderSourceOverviewAlertColumn
                    : undefined
                }
                roles={claims?.role}
                searchBaseUri={urls.searchUrl}
                searchFeedbackUrl={searchFeedbackUrl}
                searchGraphqlUrl={searchGraphqlUrl}
                searchUrlResolver={searchUrlResolver}
                siteId={siteKey}
                tabsFacet={facetsPreset.tabsFacet}
                userEmail={userInfo?.email}
                userId={userId}
                userPreferencesUrl={userPreferencesGraphqlUrl}
                userSourcesUrl={userSourcesGraphqlUrl}
                xposiApiUrl={xposiApiUrl}
                xposiUserContextUrl={xposiUserContextUrl}
              >
                <GenIALProvider
                  accessToken={accessToken}
                  applicationKey={applicationKey}
                  bluetickAiUrl={bluetickAiUrl!}
                  isEnabled={!!featureFlags.WITH_BLUETICK_AI}
                  isInitialized={authenticationProps.isInitialized}
                  isLoggedIn={authenticationProps.isLoggedIn}
                  logo={logo ? logos[logo] : undefined}
                  roles={claims?.role as string[]}
                  setNavigationShown={setShown}
                  signIn={authenticationProps.authorize}
                  signOut={authenticationProps.logout}
                  username={userInfo?.givenName || userInfo?.given_name}
                  withBetaFeatures={!!featureFlags.WITH_BLUETICK_AI_BETA_FEATURES}
                  withChatFeature={!!featureFlags.WITH_BLUETICK_AI_CHAT}
                  withFullAccess={!!featureFlags.WITH_BLUETICK_AI_FULL_ACCESS}
                  withOtherSourcesFeature={!!featureFlags.WITH_BLUETICK_AI_OTHER_SOURCES}
                  onGenIALContextError={handleGenIALError}
                >
                  <AlertsProvider>
                    <ContentRenderingProviderWithPlugins
                      apolloInitialState={ApolloCacheControl.getExtractedCache(
                        'content-rendering-provider',
                      )}
                      apolloRegisterCache={registerCacheInstanceContentRendering}
                      brandingKey={config.brandingKey}
                      dockItems={dockItems}
                      docks={docks}
                      urls={contentRenderingUrls}
                    >
                      <OfficeDocsProvider
                        accessToken={accessToken}
                        graphqlUrl={officeDocsGraphqlUrl}
                      >
                        <TitanCmsProvider
                          apolloInitialState={ApolloCacheControl.getExtractedCache(
                            'titan-cms-provider',
                          )}
                          apolloRegisterCache={registerCacheInstanceTitanCms}
                        >
                          <SearchAutocompleteProvider list={autocompleteList}>
                            <NavigationProvider>
                              <XposiProvider>
                                <InPageSearchProviderWithRouter>
                                  {children}
                                </InPageSearchProviderWithRouter>
                              </XposiProvider>
                            </NavigationProvider>
                          </SearchAutocompleteProvider>
                        </TitanCmsProvider>
                      </OfficeDocsProvider>
                    </ContentRenderingProviderWithPlugins>
                  </AlertsProvider>
                </GenIALProvider>
              </SearchRootProvider>
            </ReadingListsProvider>
          </ContentExportProvider>
          <AuthComponents />
        </AnalyticsProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  );
}
interface AppProvidersProps {
  hasSimpleLayout?: boolean;
  router: AppProps['router'];
}
function AppProviders({
  children,
  hasSimpleLayout,
  router,
}: React.PropsWithChildren<AppProvidersProps>) {
  if (hasSimpleLayout) {
    return <BaseLayoutSimple>{children}</BaseLayoutSimple>;
  }

  return (
    <ConditionalWrapper
      condition={!shouldUseServerSideAuthentication}
      wrapper={(children) => <ClientOidcProvider router={router}>{children}</ClientOidcProvider>}
    >
      <AppProvidersWithAuth>
        <BaseLayout>{children}</BaseLayout>
      </AppProvidersWithAuth>
    </ConditionalWrapper>
  );
}
interface AppHocProps {
  messages: Messages;
  isBot: boolean;
  cookies: {
    [key: string]: string;
  };
}
const App = ({
  Component,
  pageProps: { session, ...pageProps },
  cookies,
  messages,
  isBot,
  router,
}: AppProps & AppHocProps) => {
  return (
    <ConditionalWrapper
      condition={shouldUseServerSideAuthentication}
      wrapper={(children) => (
        <SessionProvider
          refetchInterval={
            /** 59 minutes, in case jwt token is valid for 1 hour */
            60 * 59
          }
          refetchOnWindowFocus={false}
          session={session}
        >
          {children}
        </SessionProvider>
      )}
    >
      <TranslationsProvider messages={messages}>
        <DefaultSeo {...getDefaultSeo(config, router.locale)} />
        <ThemeProvider
          defaultYOffset={26}
          designTokens={config.designTokens}
          useLinkResolver={useNextLinkResolver}
          useLinkWrapper={useNextLinkWrapperWithVersion}
        >
          <Sentry.ErrorBoundary
            showDialog
            dialogOptions={sentryDialogOptions}
            fallback={
              <ErrorBoundaryPageFallback
                isFullScreen
                brandName={config.brandName}
                logo={config.logo}
              />
            }
          >
            <GlobalSiteStyling />
            <SiteConfigProvider
              config={{
                ...config,
                isBot,
                urls,
              }}
              cookies={cookies}
            >
              <AppProviders hasSimpleLayout={pageProps.hasSimpleLayout} router={router}>
                <Component {...pageProps} />
              </AppProviders>
            </SiteConfigProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </TranslationsProvider>
    </ConditionalWrapper>
  );
};

export default withApolloServerSideRender(
  withServerContext<any, AppProps & AppHocProps>(
    withLingui(withCookies(withBot(App)), loadMessages),
  ),
);
