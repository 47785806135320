import { ApolloError } from '@apollo/client';
import type { CustomDesignTokens } from '@elseu/sdu-titan';
import { type SearchConfig } from '@elseu/sdu-titan-product-site-components';
import { type Event, type EventHint, type init, rewriteFramesIntegration } from '@sentry/nextjs';
import getConfig from 'next/config';

import { deepMerge } from './helpers/deepMerge';

const nextConfig = getConfig();
const publicRuntimeConfig = nextConfig?.publicRuntimeConfig ?? {};

export interface NextConfig {
  alertsGraphqlUrl: string;
  allowRobots: string;
  authMode: 'server-side' | 'client-side';
  applicationKey: string;
  authUrl: string;
  brandName: string;
  brandingKey: string;
  bluetickAiUrl: string;
  bluetickGraphqlUrl: string;
  cmsGraphqlUrl: string;
  defaultLocale: string;
  designTokens: CustomDesignTokens;
  /**
   * Feature flags can contain also custom feature flags.
   * They can be used by importing the config file and using it like this.
   * `config.featureFlags.includes('WITH_MOONLIT') `
   *
   * NDFR ONLY: Moonlit suggestions for NDFR only. Currently unused untill new implementation
   * WITH_MOONLIT?: boolean;
   *
   * NDFR ONLY: share NDFR documents to taxvice
   * WITH_TAXVICE_SHARING?: boolean;
   *
   */
  featureFlags: string;
  frontOfficeUrl: string;
  graphqlUrl: string;
  officeDocsGraphqlUrl: string;
  pmtToolBaseUrl: string;
  printGraphqlUrl: string;
  redirectEndpoint: string;
  searchConfig: SearchConfig;
  searchFeedbackUrl: string;
  searchGraphqlUrl: string;
  siteKey: string;
  siteUrl: string;
  supportUrl: string;
  userPreferencesGraphqlUrl: string;
  userSourcesGraphqlUrl: string;
  version: string;

  autocompleteList?: string;
  completemeGraphqlUrl: string;
  gtmId?: string;
  intelligentSearchBaseUrl?: string;
  intelligentSearchCore?: string;
  logo?: string;
  sentryOptions?: Partial<Parameters<typeof init>[0]>;
  taxviceShareBaseUrl?: string;
}
export interface ErrorEvent extends Event {
  type: undefined;
}
export const config = deepMerge<Array<Partial<NextConfig>>>(publicRuntimeConfig, {
  sentryOptions: {
    beforeSend: (event: ErrorEvent, hint: EventHint) => {
      const isGTM = !!event.exception?.values?.[0].stacktrace?.frames?.find((frame) => {
        return !!frame.filename?.match(/gtm.js/i) || !!frame.abs_path?.match(/gtm.js/i);
      });
      if (isGTM) return null;

      const isNetworkError =
        hint.originalException instanceof ApolloError &&
        hint.originalException.networkError &&
        ['ServerParseError'].includes(hint.originalException.networkError.name);

      if (isNetworkError) {
        event.level = 'warning';
        event.fingerprint = ['network-error'];
      }

      // To debug the UnknownError error bug better https://sentry.awssdu.nl/organizations/sdu/issues/42812.
      const isUnknownError = event.message?.includes('UnknownError: Unexpected internal error');
      if (isUnknownError) {
        if (typeof event.extra === 'undefined') event.extra = {};
        event.extra.fullInfo = hint.originalException;
        event.extra.historyState = typeof window !== 'undefined' ? window.history.state : null;
        event.extra.location = typeof window !== 'undefined' ? window.location : null;
        event.level = 'warning';
      }

      const error = hint.originalException as any;
      const exception = event.exception?.values?.[0]?.value;
      const message = error && typeof error !== 'string' ? error?.message : error;
      const isMinifiedReactError =
        exception?.includes('React') ||
        message?.includes('React') ||
        event.message?.includes('React');
      const isHydrationError =
        exception?.includes('ydra') || message?.includes('ydra') || event.message?.includes('ydra');

      if (isMinifiedReactError || isHydrationError) {
        if (typeof event.extra === 'undefined') event.extra = {};
        // Get the logged in state from the cookies.
        event.extra.isLoggedInCookie = event.request?.cookies?.isLoggedIn;
        event.extra.hasGdprAcceptedLocalStorage =
          typeof window !== 'undefined' ? localStorage.getItem('gdprPublisherSelection') : null;
        event.extra.isLoggedInLocalStorage =
          typeof window !== 'undefined'
            ? localStorage.getItem('oidc_jwt_provider_logged_in')
            : null;
        event.extra.csrfTokenLocalStorage =
          typeof window !== 'undefined' ? localStorage.getItem('oidc_jwt_provider_token') : null;
        event.extra.userInfoLocalStorage =
          typeof window !== 'undefined'
            ? localStorage.getItem('oidc_jwt_provider_user_info')
            : null;
        event.extra.retryLoginLocalStorage =
          typeof window !== 'undefined'
            ? localStorage.getItem('oidc_jwt_provider_retry_login')
            : null;
        event.extra.history = typeof window !== 'undefined' ? window.history.state : null;
        event.extra.width = typeof window !== 'undefined' ? window.innerWidth : null;
      }

      return event;
    },
    integrations: [
      rewriteFramesIntegration({
        iteratee: (frame) => {
          if (frame.filename) {
            // Fix source maps doesn't work when url is encoded. (%5BswsId%5D converts to [swsId])
            frame.filename = decodeURI(frame.filename);
          }
          return frame;
        },
      }),
    ],
  },
}) as NextConfig;
